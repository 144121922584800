<main id="main" >

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backkala">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Kalavinyasa</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Kalavinyasa<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
          <h2 style="font-weight: 700;">Kalavinyasa</h2>
          <p style="text-align: center;">Giving Wings to Imagination</p> <br>
          <p style="text-align: justify;">Art gives students a chance to show their creative side. Vinyasa gives students access to national and international opportunities to let them showcase their talent to the world. Through this, we intend to boost their confidence and let them explore various career avenues in arts.</p><!-- <p style="text-align: center;">BCCV: “Blind Cricket Club of VINYASA” the motive behind organizing blind cricket for visually impaired players to showcase their great talent

          </p> <br>
          <p style="text-align: center;">We encourage them to show thier special talent in "Blind Cricket" and also in athletics games.

          </p> -->
        </div>

      <div class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-app" (click)="Load()">Kalavinyasa</li>
            <!-- <li data-filter=".filter-card">Card</li> -->
            <!-- <li data-filter=".filter-web">Web</li> -->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300" >

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links" >
                <a href="assets/img/portfolio/kala.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala1.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala1.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala2.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala2.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 portfolio-item filter-app1">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala1.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala1.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 portfolio-item filter-app1">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala2.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala2.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kalavinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala.jpeg" data-gall="portfolioGallery" class="venobox" title="Kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="Kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section><!-- End Portfolio Section -->
</main><!-- End #main -->
