<!-- ======= Breadcrumbs ======= -->

<section class="hero-wrap hero-wrap-2 back1">
  <div class="overlay">

  </div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
        </p>
        <h1 class="mb-2 bread">Our Corpose Donor</h1>

        <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                class="ion-ios-arrow-forward"></i></a></span> <span>Corpose Donor <i
              class="ion-ios-arrow-forward"></i></span></p>
      </div>
    </div>
  </div>
</section>


<section id="feature" class="transparent-bg">
  <div class="container">

      <div class="clients-area center wow fadeInDown">
          <h2>Our Corpose Donor</h2>
      </div>

      <div class="row">
          <div *ngFor="let g of this.cmsdata.corporate" class="col-md-3 wow fadeInDown">
              <div class="clients-comments text-center">
                  <img src="{{g.image}}" class="img-circle" alt="Jeevanand">
                  <h4><span>{{g.heading}}</span></h4>
              </div>
          </div>
       </div>



</div>
</section>
