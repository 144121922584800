<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backdonate">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Privacy Policy</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Privacy Policy<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->

  <section id="about" class="about1">
    <div class="container">

      <div class="section-title">
        <h2 style="font-weight: 600;">Privacy Policy</h2>

      </div>

      <div class="row content">
        <div class="col-lg-12">
          <p>1.	Vinyasa Trust is committed to the ethical collection, retention and use of information that you provide to us about yourself on this site www.vinyasatrust.org (“Website").</p>
          <p>2.	Your Personal Information may comprise the following: Name, Age, Email, Mobile Number, Payment processing details, and any other data as website may require.</p>
            <p> YOUR USE OF THE WEBSITE CONSTITUTES YOUR CONSENT TO THE ALL THE TERMS AND CONDITIONS CONTAINED IN THIS PRIVACY POLICY (AS AMENDED FROM TIME TO TIME) AND YOU SHALL BE BOUND BY THE SAME.</p>
              <p> 3.	Collection of Information</p>
                <p> 	Site Browsing: You browse the Site anonymously. We do not require you to identify yourself or reveal any Personal Information while browsing through the Site. However, you may not be able to access certain sections of the Site or interact with us without supplying us with Personal Information. For instance, you would not be able to transact at the Site or make any donations at the Site, without providing the Site with Personal Information.</p>
                  <p> 	While you are browsing through the Site, the Site’s operating system may automatically record some general information (‘General Information’) about your visit such as:</p>
                    <p>1.	the date and time you visit the Site, along with the address of the previous website you were visiting, if you linked to us from another website</p>
                      <p> 2.	the type of browser you are using (such as Internet Explorer version ‘X’)</p>
                        <p> 3.	which ‘hit’ it is on the Site</p>
                          <p> 	The General Information is not Personal Information. Website`s tracking system does not record personal information about individuals or link this information to any Personal Information collected from you.</p>
                            <p> 	The General Information is used by Vinyasa Trust for statistical analysis, for tracking overall traffic patterns on the Site and to gauge the public interest in Vinyasa Trust and the Site. Vinyasa Trust may share such General Information with any person, at Vinyasa Trust discretion.</p>
                              <p> 4.	Usage of Information</p>
                                <p> 	Personal information will be used by Vinyasa Trust for internal purposes including the following:</p>
                                  <p>  1.	sending you inter alia emails, features, promotional material, surveys, brochures, catalogues, reminders for donations, regular updates on the utilization of donations by Vinyasa Trust and other updates.</p>
                                    <p>  2.	Processing your donations to Vinyasa Trust on the Site.</p>
                                      <p>  3.	Receipt for donations made by you to Vinyasa Trust.</p>
                                        <p>  4.	Maintaining an internal confidential database of all the Personal Information collected from visitors to the Site.</p>
                                          <p>  5.	Vinyasa Trust endeavours to use up-to-date security measures to protect your Personal Information.</p>
                                            <p>  6.	Vinyasa Trust however does not make any express or implied warranty with respect to the security measures that it may employ from time to time for the protection of the Personal Information.
            </p>

            <p>Evaluating and administering the Site and Vinyasa Trust activities, responding to any problems that may arise and gauging visitor trends on the Site.</p>

              <p>7.	Disclosure of Personal Information by Vinyasa Trust
                <p>	Within Vinyasa Trust, access to Personal Information collected by Vinyasa Trust will be given only to those persons who are authorized by Vinyasa Trust. Vinyasa Trust will provide access to third parties for inter alia entering and managing Personal Information in Vinyasa Trust Database, processing your donations preparing address labels, sending emails, which require such third parties to have access to your Personal Information. Vinyasa Trust Schools cannot guarantee that such parties will keep your Personal Information confidential and Vinyasa Trust will not be liable in any manner for any loss of confidentiality attributable to such third parties.</p>
                  <p>	Vinyasa Trust may share Personal Information with any of persons who are associated with Vinyasa Trust, including companies and non-governmental organisations affiliated with Vinyasa Trust in any manner. Vinyasa Trust will retain ownership rights over such information and will share only such portions of the Personal Information as it deems fit.</p>
                    <p>	Vinyasa Trust is not liable in any manner whatsoever for the loss, damage (whether direct, indirect, consequential or incidental) or harm caused to you by the misuse of your Personal Information by a third party who is not an employee of Vinyasa Trust.</p>
                      <p>	Notwithstanding anything contained herein or any other contract between you and Vinyasa Trust, we reserve the right to disclose any Personal Information about you without notice or consent as needed to satisfy any requirement of law, regulation, legal request or legal investigation, to conduct investigations of breaches of law, to protect the Site, to protect Vinyasa Trust and it’s property, to fulfil your requests, to protect our visitors and other persons and if required by the policy of Vinyasa Trust.</p>


        </div>
      </div>

    </div>
  </section>





</main><!-- End #main -->
