
  <main id="main">

    <!-- ======= Breadcrumbs ======= -->

    <section class="hero-wrap hero-wrap-2 back1">
      <div class="overlay">

      </div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9 ftco-animate text-center">
            <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
            </p>
            <h1 class="mb-2 bread">Contact Us</h1>

            <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                    class="ion-ios-arrow-forward"></i></a></span> <span>Contact us <i
                  class="ion-ios-arrow-forward"></i></span></p>
          </div>
        </div>
      </div>
    </section>

    <!-- ======= Contact Section ======= -->


    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>GET IN TOUCH</h2>
        </div>

        <div>
          <iframe style="border:0; width: 100%; height: 320px;" src="https://maps.google.com/maps?q=vinysas%20trust&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" allowfullscreen></iframe>
        </div>
 <br>
        <div class="row">

          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 info" data-aos="fade-up">
                <i class="bx bx-map"></i>
                <h4>Address</h4>
                <p style="text-align: justify;">Anagha #21 3rd Main Rd behind SR Krishnappa Garden BHEL Officers Layout 4th T Block East Jayanagar Bengaluru Karnataka 560041</p>
              </div>
              <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="100">
                <i class="bx bx-phone"></i>
                <h4>Call Us</h4>
                <p>+91 9739356249</p>
              </div>
              <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="200">
                <i class="bx bx-envelope"></i>
                <h4>Email Us</h4>
                <p>info@vinyasatrust.org</p>
                <p>vinyasatrust@gmail.com</p>
              </div>
              <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="300">
                <i class="bx bx-time-five"></i>
                <h4>Working Hours</h4>
                <p>Mon - Sun: 8AM to 9PM</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <!-- <form (ngSubmit)="send()" class="php-email-form" data-aos="fade-up">
              <div class="form-group">
                <input placeholder="Your Name" type="text" name="name" [(ngModel)]="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Your Email" type="email" class="form-control" [(ngModel)]="email" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Your Phone Number" type="text" class="form-control" [(ngModel)]="mobile" name="mobile" id="mobile" data-rule="email" data-msg="Please enter a Mobile Number" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Subject" type="text" class="form-control"  [(ngModel)]="subject" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <textarea placeholder="Message" class="form-control" name="message" [(ngModel)]="message" rows="5" data-rule="required" data-msg="Please write something for us"></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div *ngIf="error" class="error-message">Your message has not been sent. Sorry please try after sometime!</div>
                <div *ngIf="show" class="sent-message">Your message has been sent. Thank you!</div>
             </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form> -->

            <form action="https://formsubmit.co/7e50696e04600efec8d920299bb44e2f" method="post" role="form" class="php-email-form" data-aos="fade-up" #form>
              <div class="form-group">
                <input placeholder="Your Name" type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Your Email" type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Your Phone Number" type="number" class="form-control" name="number" id="mobile" data-rule="minlen:4" data-msg="Please enter a Mobile Number" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input placeholder="Subject" type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <textarea placeholder="Message" class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us"></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit" (click)="form.submit()">Send Message</button></div>
              <input type="hidden" name="_captcha" value="false">
              <input type="hidden" name="_template" value="table">

            </form>
          </div>

        </div>

      </div>
    </section>

  </main><!-- End #main -->
