<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backswachha">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Swachhamaya</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Swachhamaya <i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <br>
  <br>
  <!-- ======= Features Section ======= -->
  <section id="features" class="features">
    <div class="container">

      <div class="section-title" data-aos="fade-up">
        <h2><strong>Swachhamaya Waste to Value</strong> </h2>
        <p>Valuable recycling items.</p>
      </div>

      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0" data-aos="fade-right">
          <ul class="nav nav-tabs flex-column">
            <li class="nav-item">
              <a class="nav-link active show" data-toggle="tab" href="#tab-1">
                <!-- <h4>Modi sit est</h4> -->
                <p style="text-align: justify;">1.] Paper and Cardboard - Old newspapers, books and magazines would be used by our (blind) visually impaired students for making braille study material. </p>
              </a>
            </li>
            <li class="nav-item mt-2">
              <a class="nav-link" data-toggle="tab" href="#tab-2">
                <!-- <h4>Unde praesentium sed</h4> -->
                <p style="text-align: justify;">2.] Plastics/Bottles - Loose plastic bags, plastic shopping bags and any plastic containers found in your home.</p>
              </a>
            </li>
            <li class="nav-item mt-2">
              <a class="nav-link" data-toggle="tab" href="#tab-3">
                <!-- <h4>Pariatur explicabo vel</h4> -->
                <p style="text-align: justify;">3.] E-waste – electronic waste.</p>
              </a>
            </li>
            <li class="nav-item mt-2">
              <a class="nav-link" data-toggle="tab" href="#tab-4">
                <!-- <h4>Nostrum qui quasi</h4> -->
                <p style="text-align: justify;">4.] Metals – Tin, aluminium and steel cans.</p>
              </a>
            </li>
            <li class="nav-item mt-2">
              <a class="nav-link" data-toggle="tab" href="#tab-5">
                <!-- <h4>Nostrum qui quasi</h4> -->
                <p style="text-align: justify;">5.] Old Clothes & Others Recycling Items.</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-7 ml-auto" data-aos="fade-left" data-aos-delay="100">
          <div class="tab-content">
            <div class="tab-pane active show" id="tab-1">
              <figure>
                <img src="assets/img/s1.jpeg" alt="paper & cardboard" class="img-fluid">
              </figure>
            </div>
            <div class="tab-pane" id="tab-2">
              <figure>
                <img src="assets/img/Plastic_waste.jpg" alt="Plastic" class="img-fluid">
              </figure>
            </div>
            <div class="tab-pane" id="tab-3">
              <figure>
                <img src="assets/img/E-waste.jpg" alt="Electronic waste" class="img-fluid">
              </figure>
            </div>
            <div class="tab-pane" id="tab-4">
              <figure>
                <img src="assets/img/metals ewaste.jpg" alt="Metals" class="img-fluid">
              </figure>
            </div>
            <div class="tab-pane" id="tab-5">
              <figure>
                <img src="assets/img/other recycle.jpg" alt="Other Recycling items" class="img-fluid">
              </figure>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Features Section -->

</main><!-- End #main -->
