<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backfeed">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Feedback</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Feedback<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Testimonials Section ======= -->



  <section class="client_section layout_padding-bottom">
    <div class="container">
      <div class="custom_heading-container">
        <h2 style="text-align: center;">
          What the donar says:
        </h2>
      </div>
    </div>
  <br>
    <div class="container">
      <div id="carouselExample2Controls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="client_container layout_padding2">
              <div class="client_box b-1">
                <div class="client-id">
                  <div class="img-box">
                    <img src="assets/img/testimonials/Ravishankar.jpg" alt="" />
                  </div>
                  <div class="name">
                    <h5>
                      K R Raviprakash
                    </h5>
                    <p>
                      Public
                    </p>
                  </div>
                </div>
                <div class="detail">
                  <p style="text-align: justify;">
                    I have been associated with Vinyasa Trust  For The Disabled ever since it was formed even before it's formation. It is indeed a very dedicated effort by the trustees for the over all development of the differently abled persons. They are really trying very hard to give the best education, food, accommodation extracurricular activities for them.
                It is really touching to see the challenge taken by two young trustees one who can hardly see BLIND BY BIRTH the other  can hardly move on his own WHEEL CHAIR BOUND FOR  LIFE SINCE BIRTH and ablely supported by two other trustees who are like mother's to Vinyasiyans.
   They definitely deserve all the best possible support in their endeavours, especially by the two differentlly abled Trustees
                  </p>
                </div>
              </div>
              <div class="client_box b-2">
                <div class="client-id">
                  <div class="img-box">
                    <img src="assets/img/testimonials/Sandhya .jpg" alt="" />
                  </div>
                  <div class="name">
                    <h5>
                      Sandhya Shantharam
                    </h5>
                    <p>
                      Public
                    </p>
                  </div>
                </div>
                <div class="detail">
                  <p style="text-align: justify;">
                    I have been volunteering in the field of education for the  visually disabled students for past six years.  When I took a decision to retire from a senior management position in a software company a few years back, I started with audio recording of text books and later started teaching the visually challenged students at Vinyasa Trust.  This experience has been truly fulfilling.
                    The atmosphere at Vinyasa is like an extended family with many cultural and sports events. Each student is encouraged to pursue their passion. The lively youngsters at Vinyasa are a proof of the atmosphere of loving care that Vinyasa provides. I wish Vinyasa many more years of service in the cause of the differently abled.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="client_container layout_padding2">
              <div class="client_box b-1">
                <div class="client-id">
                  <div class="img-box">
                    <img src="assets/img/testimonials/Satish Kumar N.jpeg" alt="" />
                  </div>
                  <div class="name">
                    <h5>
                      Satish Kumar N
                    </h5>
                    <p>
                      Director Software Engineering @ AMD
                    </p>
                  </div>
                </div>
                <div class="detail">
                  <p style="text-align: justify;">
                    Vinyasa Trust is doing a great job in taking care and providing support for differently challenged people. I am impressed with thier commitment to uplift these people and glad to be associated with them.
                  </p>
                </div>
              </div>
              <div class="client_box b-2">
                <div class="client-id">
                  <div class="img-box">
                    <img src="assets/img/testimonials/Shekar Naik.jpeg" alt="" />
                  </div>
                  <div class="name">
                    <h5>
                      Shekhar Naik
                    </h5>
                    <p>
                      Retired Indian blind cricket team captain
                    </p>
                  </div>
                </div>
                <div class="detail">
                  <p style="text-align: justify;">
                    Dear vinaysa trust you guys are amazing working from the grass root level. Very proud to be a supporter. you are work for specially abled it’s just awesome I am really very much impressed on your service. Many congratulations.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExample2Controls" role="button" data-slide="prev">
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExample2Controls" role="button" data-slide="next">
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </section>

</main><!-- End #main -->
