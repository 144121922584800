<main id="main">

  <!-- ======= Breadcrumbs ======= -->

  <section class="hero-wrap hero-wrap-2 backnews">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Student</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Student<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

<section id="team" class="team">
  <div class="container">

    <div class="section-title">
      <!-- <span>Team</span> -->
      <h2>Top Student of Vinaysa Trust </h2>
      <!-- <p>Sit sint consectetur velit quisquam cupiditate impedit suscipit alias</p> -->
    </div>

    <div *ngIf="this.cmsdata.students.length === 0" class="row">
      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
        <div class="member">
          <img src="assets/img/team/student1.jpeg" alt="">
          <h4>Thirumalaiah C</h4>
          <span>Surana College</span>
          <p>
           Percentage: 96%.
           Year: 2023. Course: Commerce 2nd PUC.
           Disability: Visually impaired
          </p>

        </div>
      </div>

      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
        <div class="member">
          <img src="assets/img/team/student2.jpeg" alt="">
          <h4>Karan Kumar</h4>
          <span>A.P.S.College of Arts and Science</span>
          <p>
            Percentage: 65%.
            Year: 2023. Course: Arts 2nd PUC.
            Disability: Visually impaired
           </p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
        <div class="member">
          <img src="assets/img/team/student3.jpeg" alt="">
          <h4>Ramalinga</h4>
          <span>A.P.S.College of Arts and Science</span>
          <p>
            Percentage: 58%.
            Year: 2023. Course: Arts 2nd PUC.
            Disability: Visually impaired
           </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
        <div class="member">
          <img src="assets/img/team/student4.jpeg" alt="">
          <h4>Gagan M V</h4>
          <span>A.P.S.College of Arts and Science</span>
          <p>
            Percentage: 45%.
            Year: 2023. Course: Arts 2nd PUC.
            Disability: Visually impaired
           </p>
         </div>
      </div>

    </div>

    <div *ngIf="this.cmsdata.students.length > 0" class="row">
      <div *ngFor="let g of this.cmsdata.students" class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
        <div class="member">
          <img src="{{g.image}}" alt="{{g.heading}}">
          <h4>{{g.heading}}</h4>
          <span>{{g.subheading}}</span>
          <p innerHtml="{{g.content}}">
          </p>

        </div>
      </div>

    </div>
<br>



  </div>
</section>

</main>
