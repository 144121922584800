<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backfeed">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Feedback</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Student Feedback<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Testimonials Section ======= -->


  <section id="portfolio-details" class="portfolio-details">

    <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div  id="testimonial-slider" class="owl-carousel">
                <div class="testimonial">
                  <div class="pic">
                      <img src="assets/img/team/Jyothi.jpeg">
                  </div>
                  <div class="testimonial-profile">
                      <h3 class="title">Jyothi S</h3>
                      <span class="post">APS College of Arts and Science</span>
                  </div>
                  <p class="description" style="text-align: justify;">
                    My name is Jyoti S and I hail from
Yerapapanahalli District of Chikkaballapur Taluk, Chikkaballapur. I'm of 21 years and have
completed my primary secondary education in Matru institution.
I have completed my PUC from Government PU College Mandokallu Chikkaballapur Taluk Chikkaballapur District. I'm pursuing my graduation in Acharya Pathshala Arts and Science College. My hobbies include listening to music, dancing, and watching movies.
The next target is dancer or District Collector.  It has been three years since I joined the Vinyasa Organization.
This organization is providing me with food, health, education, and accommodation.
I am very happy here as we live together like a family. I will always be grateful to this organization.     </div>
                  <div class="testimonial">
                      <div class="pic">
                          <img src="assets/img/team/Basavaraj.jpeg">
                      </div>
                      <div class="testimonial-profile">
                          <h3 class="title">Basavaraj G</h3>
                          <span class="post">APS College of Arts and Science</span>
                      </div>
                      <p class="description" style="text-align: justify;">
                        My name is Basavaraj
                        from Uchchal Kunta District Koppal Taluk Yalaburga.
                        I completed my primary secondary high school education from SRI SHARADA DEVI ANDHARA VIKASA KENDRA Shivamogga with 80%.

                        I completed my PUC from Acharya Pathshala Arts Commerce and Science College with 70.33%, I completed Graduation from Surana College Southend Circle with 75% and Post Graducation from Central College with 60%.
                        I did KAS coaching for two months and after that computer training it has been 5 year came to a Vinyasa Organization.
                        This organization provides me with education, health, shelter & food etc.
                        This organization is like a family to us, we are all friends in this institute, this institute fulfills all our requirements.                        </p>
                  </div>
                  <div class="testimonial">
                      <div class="pic">
                          <img src="assets/img/team/NarashimaMurthy.jpeg">
                      </div>
                      <div class="testimonial-profile">
                          <h3 class="title">Narasimha Murthy</h3>
                          <span class="post">APS College of Arts and Science</span>
                      </div>
                      <p class="description" style="text-align: justify;">
Hi team, Myself Narasimha Murthy.
I have completed my graduation in APS college affiliated to Bangalore Central University.  I secured an aggregate of 78%  here.
My experience in vinyasa organization is good. I have gained a lot of knowledge and learnt to be disciplined.
It provides facilities such as a healthcare, healthy food, and good atmosphere.
Vijaya ma'am, Abdul anna, and Madhu anna supported me a lot. I will always be grateful to the Vinyasa team- thank you.
                      </p>
                  </div>

                  <div class="testimonial">
                    <div class="pic">
                        <img src="assets/img/team/Parsuram.jpeg">
                    </div>
                    <div class="testimonial-profile">
                        <h3 class="title">Parashuram D Mugadur</h3>
                        <span class="post">National College Jayanagar</span>
                    </div>
                    <p class="description" style="text-align: justify;">
                       My name is Parashuram D Mugadur and I hail from Haveri.
I completed 10th standard in SRI SHARADA DEVI ANDHARA VIKASA KENDRA Shivamogga blind school with
88.96 percentage. Then, I came to bangalore for PUC. I am staying in Vinyasa Trust. I completed PUC in BES college with 68.38 percentage in 2nd PUC. I finished my degree education in the National College with 64.68%. Currently, I stay in Vinyasa Trust.
It is helping me with my accommodation, college fees, examination fees, healthcare and even provided good food. I'm grateful to Vinyasa for everything.
                    </p>
                </div>


                  <div class="testimonial">
                    <div class="pic">
                        <img src="assets/img/team/Jagadeesh.jpeg">
                    </div>
                    <div class="testimonial-profile">
                        <h3 class="title">Jagadeesh P H</h3>
                        <span class="post">National College Jayanagar</span>
                    </div>
                    <p class="description" style="text-align: justify;">
                        My name is Jagadeesh P H, and I am in Vinyasa organization since 2018.
Before I came to Bengaluru, I studied in SRI SHARADA DEVI ANDHARA VIKASA KENDRA Shivamogga, a school for blind. I passed my SSLC with an aggregate of 80%.
I have completed 5 years of journey with Vinyasa trust and now have completed my BA graduation with their help. They provide me with food and shelter and help with admission fee, exam fee, and much more. They also encourage me to continue in my fields of interest such as athletics, cricket, etc. I played for the Vinyasa cricket team, and I representrd the Karnataka cricket team.
according to me, this organisation is doing a good job and I request everyone to make use of it.
I will be grateful to Vinyasa Trust for differently challenged until my last breath.

                    </p>
                </div>


                <div class="testimonial">
                    <div class="pic">
                        <img src="assets/img/team/Vasanth.jpeg">
                    </div>
                    <div class="testimonial-profile">
                        <h3 class="title">Vasanth </h3>
                        <span class="post">National College Jayanagar</span>
                    </div>
                    <p class="description" style="text-align: justify;">
                       This is Vasanth from Shivamogga. I would like to share my experience at Vinyasa trust. I would like to begin by thanking to trustees and founders of Vinyasa. This is my 4th year here before that, i finished my S.S.L.C in Sri Sharadhadevi blind school in Shivamogga. In my 10th standard i got 96%. Then, i came to Bengaluru in 2019. Here, i joined Vinyasa trust for my higher studies at the hostel, i have received good shelter, food, and volunteer service. When it comes to education, I have really been getting the best support.
                       My friends and i joined A.P.S college for 1st P.U.C and completed our 2nd year too, in which i passed out at 91%. Currently, I'm pursuing my B.A graduation in National College, Jayanagar. Apart from education, everyone in the hostel supports sports and other cultural activities. Especially, cricket. We've participated in many tournaments and won tournaments as well. I want to share one more thing: the staff and the trustees are very friendly. We are very grateful to have good volunteers. Once again, i would like to thank the trust.
                    </p>
                </div>

              </div>
              <div  id="testimonial-slider" class="owl-carousel">
                <div  class="testimonial">
                  <div *ngFor="let g of this.cmsdata.studentfeedback">
                    <div class="pic">
                      <img src="{{g.image}}">
                  </div>
                  <div class="testimonial-profile">
                      <h3 class="title">{{g.heading}}</h3>
                      <span class="post">{{g.subheading}}</span>
                  </div>
                  <p class="description" style="text-align: justify;">
                   {{g.content}}
                  </p>
                  </div>

                </div>
            </div>
          </div>
      </div>
  </div>

  </section>

</main>
