import { Component, OnInit } from '@angular/core';
import { VinyasaService } from '../vinyasa.service';

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.css"],
})
export class ActivitiesComponent implements OnInit {
  constructor(public cmsdata: VinyasaService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  checkeven(val) {
    if (val % 2 === 0) return true;
    else return false;
  }
}
