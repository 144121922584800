<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backdonate">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Donate</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Donate<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->

  <section id="about" class="about1">
    <div class="container">

      <div class="section-title">
        <h2 style="font-weight: 600;">Support Us</h2>
        <p>"A Smile Some love And a Little Contribution".
          Even the smallest of things can make the biggest difference.
          Help us in making the lives better of our special children with your
          valuable time donations, support and Blessings</p>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
            1. Celebrating your special days such as birthdays, wedding anniversary and festivals with our students. As a donor, you could sponsor special meals (breakfast/lunch/dinner), and cherish your wonderful moments with our students. (Breakfast Rs. 3500, Lunch Rs. 4500 and Dinner Rs. 4500)

          </p>
          <p>
            2. Sponsoring education, adopting a student at Rs.18000/- per annum, covering the entire cost of education.
            <p>  Rs. 50 per student for per day (50*360 days = 18000)</p>
             <p> Rs. 100 a student for per day including education & food (100*360 days = 36000) </p>

          <p>
            3. Paying monthly building rent of Rs. 45000/- and the electricity Rs. 10000/- approximately, water Rs. 10000/- approximately, internet expenses of Rs.2000/- approximately
          </p>

        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            4. Sponsoring grocery: Rice, Refined oil, Dal items, Sugar, Tea powder, Coffee powder, Avalakki, Masala Items, Milk, Coconut and Vegetables. Donors can also donate a cash amount towards the purchase of provisions.
<p>a). Daily 6 liters milk and 3 liters curd
(Weekly Rs. 1680/- for Milk and Rs. 945/- for Curd)</p>
<p>b). Daily Rs. 250/- Vegetables required.
(Weekly Vegetables Rs. 1750/-)</p>
<p>c). Gas Cylinder (Monthly Rs. 8000/- required</p>

        <p>
          5. Donating in kind: educations equipment, cricket kit and sports equipment for visually impaired students.
        </p>
        </div>
      </div>

    </div>
  </section>




  <div class="blog1">
    <div class="container">
        <div class="section-header text-center">

            <h2>Bank Details</h2>
          <br>
           </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                <div class="blog-item">
                    <div class="blog-img">
                        <img src="assets/img/Union Bank of India.jpeg" alt="Image">
                    </div>
                    <div class="blog-title">
                        <h3>Union Bank of India</h3>
                    </div>

                    <div class="blog-text">
                     <p> Account Name: Vinyasa Trust for Differently Challenged</p>
                     <p> Bank Name: Union Bank of India</p>
                      <p>A/c Number: 520101254941006</p>
                      <p>Branch: J.P Nagar 2nd Phase</p>
                      <p>IFSC Code: UBIN0916021</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp">
                <div class="blog-item">
                    <div class="blog-img">
                        <img src="assets/img/donateimg.jpeg" alt="Image">
                    </div>
                    <div class="blog-title">
                        <h3>Vinyasa Trust</h3>
                    </div>

                    <div class="blog-text">
                        <p>
                          Vinyasa means special attention or special way, and Vinyasa Trust was formed to ensure the welfare of the differently challenged underprivileged students of Karnataka.                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                <div class="blog-item">
                    <div class="blog-img">
                        <img src="assets/img/Equitas Small Finance Bank.jpeg" alt="Image">
                    </div>
                    <div class="blog-title">
                      <h3>Equitas Small Finance Bank</h3>
                  </div>

                    <div class="blog-text">
                      <p> Account Name: Vinyasa Trust</p>
                      <p> Bank Name: Equitas Small Finance Bank</p>
                       <p>A/c Number: 100034363574</p>
                       <p>Branch: Jayanagar 4th T Block</p>
                       <p>IFSC Code: ESFB0003001</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</main><!-- End #main -->
