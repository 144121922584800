import { Component, OnInit } from '@angular/core';
import { VinyasaService } from '../vinyasa.service';

@Component({
  selector: 'app-clientfeedback',
  templateUrl: './clientfeedback.component.html',
  styleUrls: ['./clientfeedback.component.css']
})
export class ClientfeedbackComponent implements OnInit {

  constructor(public cmsdata: VinyasaService) { }

  ngOnInit(): void {
    //window.location.reload();
  }

}
