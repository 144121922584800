<!-- Floating Social Media bar Starts -->
<!-- <div class="float-sm">
  <div class="fl-fl float-tw" title="Facebook">
    <i class="fa1 fa fa-facebook"></i>
    <a href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank">Facebook</a>
  </div>
  <div class="fl-fl float-gp" title="Instagram">
    <i class="fa1 fa fa-instagram"></i>
    <a href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank">Instagram</a>
  </div>
  <div class="fl-fl float-rs" title="Whatsapp">
    <i class="fa1 fa fa-whatsapp"></i>
    <a href="https://api.whatsapp.com/send?phone=919739356249&text=Hello" target="_blank">Whatsapp</a>
  </div>
  <div class="fl-fl float-ig" title="Phone">
    <i class="fa1 fa fa-phone"></i>
    <a href="tel:+91 9739356249" target="_blank">Call Us</a>
  </div>
  <div class="fl-fl float-pn" title="SMS">
    <i class="fa1 fa fa-envelope"></i>
    <a href="sms://+919739356249" target="_blank">Message</a>
</div>
<div class="fl-fl float-add" title="Contact Us">
  <i class="fa1 fa fa-book"></i>
  <a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >Contact Us</a>
  </div>
</div> -->

<div class="sticky-container">
  <ul class="sticky">
      <li>
          <img src="assets/img/facebook.png" width="32" height="32">
          <p ><a href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank">Like Us on <br>Facebook</a></p>
      </li>
      <li>
          <img src="assets/img/instagram.png" width="32" height="32">
          <p><a href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank">Follow Us on<br>Instagram</a></p>
      </li>
      <li>
        <img src="assets/img/youtube.png" width="32" height="32">
        <p><a href="https://www.youtube.com/watch?v=pNkuDdbPPk0" target="_blank">Subcribe Us on <br> YouTube</a></p>
    </li>
      <li>
          <img src="assets/img/whatsapp.png" width="32" height="32">
          <p><a href="https://api.whatsapp.com/send?phone=919739356249&text=Hello" target="_blank">Contact Us On <br> Whatsapp</a></p>
      </li>
      <li>
          <img src="assets/img/Call1.png" width="32" height="32">
          <p><a href="tel:+91 9739356249" target="_blank">Call Us</a></p>
      </li>
      <li>
        <img src="assets/img/Email.png" width="32" height="32">
        <p> <a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >Contact Us</a></p>
    </li>
    <li>
      <img src="assets/img/linkedin.jpeg" width="32" height="32">
      <p><a href="https://www.linkedin.com/in/vinyasa-trust-for-differently-challenged-950480172" target="_blank">Join Us On <br> LinkedIn</a></p>
  </li>
  </ul>
</div>
