<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backacc">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Vasativinyasa</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Vasativinyasa<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">


      <div class="section-title">
        <h2 style="font-weight: 700;">Accomodation and Food</h2>
        <p>Ensuring Well-Being Through Hygiene </p> <br>
        <p style="text-align: justify;">We promote hygienic living through tidy premises and a clean and well-planned food regime. This way, they are not only healthy but also happy.
      </p> <br>

      <p style="text-align: justify;">You too can help us keep our students happy by celebrating your special days like birthdays, wedding anniversaries or any other occasions/festivals with our students. As a donor, you could sponsor special meals (breakfast/lunch/dinner), and cherish your wonderful moments with our students.
      </p>
  </div>

      <div class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-app" (click)="Load()">Vasativinyasa</li>
            <!-- <li data-filter=".filter-card">Card</li> -->
            <!-- <li data-filter=".filter-web">Web</li> -->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/accommodation.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation1.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/accommodation1.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation2.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/accommodation2.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation3.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/accommodation3.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation4.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/accommodation4.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/accommodation3.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Vasativinyasa</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links" >
                <a href="assets/img/portfolio/accommodation3.jpg" data-gall="portfolioGallery" class="venobox" title="Vasativinyasa"><i class="bx bx-plus"></i></a>
                <a href="Accommodation.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section><!-- End Portfolio Section -->
</main><!-- End #main -->
