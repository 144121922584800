<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backdonate">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">TERMS & CONDITIONS</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Terms & Conditions<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->

  <section id="about" class="about1">
    <div class="container">

      <div class="section-title">
        <h2 style="font-weight: 600;">TERMS & CONDITIONS</h2>

      </div>

      <div class="row content">
        <div class="col-lg-12">
          <p>
            This website is owned and managed by Vinyasa Trust. By accessing and using the www.vinyasatrust.org the "Web Site", you are agreeing to be legally bound by these Terms & Conditions. The terms "you" and "User" refer to anyone who accesses the Web Site.</p>

<p>As you browse through the website and NGO sites you may access other web sites that are subject to different terms of use. When you use those sites, you will be legally bound by the specific terms of use posted on such sites. If there is a conflict between these Terms & Conditions and the other terms and conditions, the other terms & conditions will govern with respect to use of such pages.</p>


<p>Vinyasa Trust may change these Terms & Conditions at any time without notice. Changes will be posted on the website under “Terms & Conditions”.

<p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p>
<p>The Web Site and the content, including, but not limited to, text, data, reports, opinions, images, photos, graphics, graphs, charts, animations, and video (the "Content"), displayed on the Web Site, may be used only for your personal and non-commercial use. Except as otherwise permitted under these Terms & Conditions, you agree not to copy, reproduce, modify, create derivative works from, or store any Content, in whole or in part, from the Web Site or to display, perform, publish, distribute, transmit, broadcast or circulate any Content to anyone, or for any commercial purpose, without the express prior written consent of Vinyasa Trust.</p>
  <p>Unauthorised use of this website may lead to a claim for damages and/or be a criminal offence.</p>
    <p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
      <p>You may not create a link to this website from another website or document without Vinyasa Trust prior written consent.</p>
        <p>You use of this website and any dispute arising out of such use of the website is subject to the laws of India.



          </p>

        </div>
      </div>

    </div>
  </section>





</main><!-- End #main -->
