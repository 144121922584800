import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) { }
  email: string;
name: string;
subject: string;
mobile: string;
message: string;
dest = 'vinyasatrust@gmail.com;inseventech@gmail.com';
address='';
show:boolean = false;
error = false;
  ngOnInit(): void {
    window.scroll(0,0);
  }

  send() {
    // TODO: Use EventEmitter with form value
    const endpoint = 'https://us-central1-emailserverin7.cloudfunctions.net/sendMail?';
    //// console.log(this.profileForm.value.date.toLocaleDateString());
    // tslint:disable-next-line: max-line-length
    this.http.get(endpoint + 'dest=' + this.dest + '&sub=' + encodeURIComponent(this.subject) + '&name=' + encodeURIComponent(this.name) + '&address=' + encodeURIComponent(this.address) + '&email=' + encodeURIComponent(this.email) + '&mobile=' + this.mobile +
       '&message=' + encodeURIComponent(this.message))
      .subscribe((data) => {
     //   console.log(data);

        this.show = true;
        this.email='';
        this.name='';
        this.subject='';
        this.mobile='';
        this.message='';

      }, (er) => {
        // console.log(er);

        this.show = true;
        this.email='';
        this.name='';
        this.subject='';
        this.mobile='';
        this.message='';
       }
      );
  }

}
