 <!-- ======= Top Bar ======= -->

 <section id="topbar" class="d-none d-lg-block">
  <div class="container d-flex">
    <div class="contact-info mr-auto">
      <i class="icofont-envelope"></i><a href="mailto:info@vinyasatrust.org">info@vinyasatrust.org</a>
      <i class="icofont-envelope"></i><a href="mailto:vinyasatrust@gmail.com">vinyasatrust@gmail.com</a>
      <i class="icofont-phone"></i> <a href="tel:9739356249">+91 9739356249</a>
      <i class="icofont-clock-time"></i> <a ><iframe src="https://freesecure.timeanddate.com/clock/i7m43cg1/n4687/tlin/fc4d4643/tcf8f6f5/pcf8f6f5/tt0/tw0/tm1/th2" frameborder="0" width="169" height="18"></iframe>
      </a>
    </div>
    <div class="social-links">
      <a href="https://www.facebook.com/profile.php?id=100015178126409" class="facebook" target="blank"><i class="icofont-facebook"></i></a>
      <a href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" class="instagram" target="blank"><i class="icofont-instagram"></i></a>
      <a href="https://www.youtube.com/watch?v=pNkuDdbPPk0" class="youtube" target="blank"><i class="icofont-youtube"></i></a>
    </div>
  </div>
</section>
