<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backdonate">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Refund and Cancellation</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Refund and Cancellation<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->

  <section id="about" class="about1">
    <div class="container">

      <div class="section-title">
        <h2 style="font-weight: 600;">Refund and Cancellation</h2>

      </div>

      <div class="row content">
        <div class="col-lg-12">
         <p>1.	If Tax exemption receipt is issued then amount will not be refunded at any cost.</p>
         <p>2.	If donor by mistake made donation for example 5000/- instead of 1000/- and contact the Trust within 12 hr then the amount will be refunded otherwise it will not be refunded.</p>


        </div>
      </div>

    </div>
  </section>





</main><!-- End #main -->

