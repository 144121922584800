 <!-- ======= Header ======= -->
 <header id="header" >
  <div class="container d-flex">

    <div class="logo mr-auto">
      <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><img src="assets/img/logo123.jpg" alt="Vinyasa Trust for Differently Challenged" class="img-fluid"></a>
    </div>

    <nav class="nav-menu d-none d-lg-block">
      <ul>
        <li [ngClass]="{active: isActive('home')}" (click)="select('home')"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a></li>
        <li [ngClass]="{active: isActive('aboutus')}" (click)="select('aboutus')"><a routerLink="/aboutus" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">About Us</a></li>
        <li [ngClass]="{active: isActive('activities')}" (click)="select('contact')"><a routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Activities</a></li>
        <li class="drop-down"><a >Our Work</a>
          <ul>
            <li [ngClass]="{active: isActive('education')}" (click)="select('education')"><a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vidya Vinyasa</a></li>
            <li [ngClass]="{active: isActive('kalavinyasa')}" (click)="select('kalavinyasa')"><a routerLink="/kalavinyasa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kala Vinyasa</a></li>
            <li [ngClass]="{active: isActive('sports')}" (click)="select('sports')"><a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kreeda Vinyasa</a></li>
            <li [ngClass]="{active: isActive('accomodation')}" (click)="select('accomodation')"><a routerLink="/accomodation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vasati Vinyasa</a></li>
          </ul>
        </li>
        <li [ngClass]="{active: isActive('gallary')}" (click)="select('gallary')"><a routerLink="/gallary" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Gallery</a></li>

        <li class="drop-down"><a >Feedback</a>
          <ul>
            <li [ngClass]="{active: isActive('feedback')}" (click)="select('feedback')"><a routerLink="/feedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Donor Feedback</a></li>
            <li [ngClass]="{active: isActive('clientfeedback')}" (click)="select('clientfeedback')"><a href="/clientfeedback" >Student Feedback</a></li>

          </ul>
        </li>
        <li [ngClass]="{active: isActive('news')}" (click)="select('news')"><a routerLink="/news" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Media</a></li>
         <li class="drop-down"><a >Donate</a>
          <ul>
            <li [ngClass]="{active: isActive('donate')}" (click)="select('donate')"><a routerLink="/donate" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Donate</a></li>
            <li [ngClass]="{active: isActive('donorlist')}" (click)="select('donorlist')"><a routerLink="/donorlist" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Donor List</a></li>
            <li [ngClass]="{active: isActive('corporate')}" (click)="select('corporate')"><a routerLink="/corporate" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Corpose Donors</a></li>
           </ul>
        </li>
         <li [ngClass]="{active: isActive('student')}" (click)="select('student')"><a routerLink="/student" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Student</a></li>
        <li [ngClass]="{active: isActive('swachhamaya')}" (click)="select('swachhamaya')"><a routerLink="/swachhamaya" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Swacchamaya</a></li>
        <li [ngClass]="{active: isActive('contact')}" (click)="select('contact')"><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Contact Us</a></li>

        <!-- <li class="get-started"><a routerLink="/swachhamaya" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">swacchamaya</a></li> -->

      </ul>
    </nav>
  </div>
</header>
<!-- End Header -->

