<!-- ======= Footer ======= -->
<footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-6 col-md-6 footer-contact">
          <!-- <h3>Vinyasa Trust for Differently Challenged</h3> -->
          <img src="./assets/img/logo11.png" height="50px" alt="Vinyasa Trust for Differently Challenged">
          <br>
          <br>
          <p>
            No. 21, 3rd Main Road BHEL Officers <br> Layout Behind SR Krishnappa Garden <br>
            Jayanagar 4th 'T' Block Bengaluru - 560041
         Karnataka, India <br> <br>
            <strong>Phone:</strong>+91 9739356249 <br>
            <strong>Email:</strong> info@vinyasatrust.org<br>
            <strong>Email:</strong> vinyasatrust@gmail.com<br>
            <strong>Registration No:</strong> 1256/2017-2018/BKIV<br>
          </p>
          <br>
          <!-- weather widget start -->
          <a target="_blank" href="https://www.booked.net/weather/bengaluru-w649091"><img src="https://w.bookcdn.com/weather/picture/3_w649091_1_1_137AE9_250_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=27036&domid=w209&anc_id=48835"  alt="booked.net"/></a>
          <!-- weather widget end -->
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/aboutus" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">About us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Activities</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/gallary" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Gallery</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/feedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Feedback</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/news" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Media</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Contact</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/donate" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Donate</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/termsandconditions" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Terms & Conditions</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/privacypolicy" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Privacy Policy</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/refundandcancellation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Refund and Cancellation</a></li>

          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Work</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vidya Vinyasa</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/kalavinyasa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kala Vinyasa</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kreeda Vinyasa</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/accomodation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vasati Vinyasa</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/swachhamaya" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Swachhamaya</a></li>
          </ul>
        </div>

        <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
          <h4>Join Our Newsletter</h4>
          <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
          <form action="" method="post">
            <input type="email" name="email"><input type="submit" value="Subscribe">
          </form>
        </div> -->

      </div>
    </div>
  </div>

  <div class="container d-md-flex py-4">

    <div class="mr-md-auto text-center text-md-left">
      <div class="copyright">
        &copy; 2020 Copyright <strong><span>VINYASA TRUST</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flattern-multipurpose-bootstrap-template/ -->
        Designed by <a href="https://www.inseventechnologies.com/">INSEVEN Technologies</a>
      </div>
    </div>
    <div class="social-links text-center text-md-right pt-3 pt-md-0">
      <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->

      <a href="https://www.facebook.com/profile.php?id=100015178126409" class="facebook" target="blank" title="Facebook"><i class="bx bxl-facebook"></i></a>
      <a href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" class="instagram" target="blank" title="Instagram"><i class="bx bxl-instagram"></i></a>
      <a href="https://www.youtube.com/watch?v=pNkuDdbPPk0" class="youtube" target="blank" title="You tube"><i class="bx bxl-youtube"></i></a>
      <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
    </div>
  </div>
</footer><!-- End Footer -->
