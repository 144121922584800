import { Component, OnInit } from '@angular/core';
import { VinyasaService } from '../vinyasa.service';

@Component({
  selector: 'app-donorlist',
  templateUrl: './donorlist.component.html',
  styleUrls: ['./donorlist.component.css']
})
export class DonorlistComponent implements OnInit {

  constructor(public cmsdata: VinyasaService) { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
