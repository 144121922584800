<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backsprt">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">KreedaVinyasa</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>KreedaVinyasa<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2 style="font-weight: 700;">Sports</h2>
        <p style="text-align: center;">Nurturing the Athletic Side </p>
        <br>
        <p style="text-align: justify;">We encourage students to showcase their sporting abilities through various activities such as cricket, chess, athletics, and many more.</p>
        <br>
        <p style="text-align: justify;">One of the highlights of our program is BCCV or Blind Cricket Club of VINYASA, which aims at organizing blind cricket for visually impaired players to enjoy the game.</p>
        <br>
        <p style="text-align: justify;">Here is a list of our achievements so far: </p>
      </div>

      <table class="table table-striped">
        <tbody>
          <tr>
          <td>1. Cup holders of the 1st State Level Blind Cricket tournament against Mungod in Haverion in 2014.
          </td>
          <!-- <td>VINYASA TRUST FOR DIFFERENTLY CHALLENGED</td> -->
          </tr>
          <tr>
          <td>2. Winners of the 2nd State Level against Bangalore Lions blind cricket team in Dasarahallion in 2015.
          </td>
          <!-- <td>Corporation Bank</td> -->
          </tr>
          <tr>
          <td>3. Winners of the 3rd State Level against Samarthanam Trust for the Disabled in Hoskete in 2016.
          </td>
          <!-- <td>520101254941006</td> -->
          </tr>
          <tr>
          <td>4. Winners of the 4th State Level against Tumkur Blind Cricket team in Ranibennur on 2017.
          </td>
          <!-- <td>JP Nagar 2nd Phase
          </td> -->
          </tr>
          <tr>
          <td>5. Winners of the 2nd State level blind cricket tournament in 2017, runner up against Samarthanam Trust.
          </td>
          <!-- <td>CORP0001602</td> -->
          </tr>
          <tr>
          <td>6. Winners of the World Sight Day Cricket Championship at NAL Cricket ground, Kodihalli, Bangalore, in 2018, against Deepanjali Trust for the Blind.              </td>
            <!-- <td>CORP0001602</td> -->
          </tr>
          <tr>
          <td>7.  Winners of the Tri-Series against Deepanjali Trust for the Blind on 13th July 2019.
          </td>
              <!-- <td>CORP0001602</td> -->
          </tr>
        </tbody>
        </table>

      <div class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-app" (click)="Load()">Sports</li>
            <!-- <li data-filter=".filter-card">Card</li> -->
            <!-- <li data-filter=".filter-web">Web</li> -->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports1.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports1.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports2.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports2.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports3.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports3.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports4.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports4.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports5.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports5.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Portfolio Section -->
</main><!-- End #main -->
