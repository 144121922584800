<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backgallery">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Gallery</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Gallery<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2 style="font-weight: 700;">Gallery</h2>
        <p>All those beautiful smiles and special moments clicked and captured.</p>
      </div>

      <div *ngIf="this.cmsdata.gallery.length === 0" class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-card" (click)="Load()">Education</li>
            <li data-filter=".filter-acc" (click)="Load()">Accommodation</li>
            <li data-filter=".filter-app" (click)="Load()">Sports</li>
            <li data-filter=".filter-web" (click)="Load()">Kala Vinyasa</li>
          </ul>
        </div>
      </div>

      <div *ngIf="this.cmsdata.gallery.length > 0" class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-card" (click)="Load()">Education</li>
            <li data-filter=".filter-acc" (click)="Load()">Accommodation</li>
            <li data-filter=".filter-app" (click)="Load()">Sports</li>
            <li data-filter=".filter-web" (click)="Load()">Kala Vinyasa</li>
          </ul>
        </div>
      </div>

      <div *ngIf="this.cmsdata.gallery.length === 0" class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports1.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports1.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala.jpeg" class="img-fluid" alt="Kala vinaysa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kala Vinyasa</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala.jpeg" data-gall="portfolioGallery" class="venobox" title="Kala Vinyasa"><i class="bx bx-plus"></i></a>
                <a href="kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports2.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports2.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education5.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <!-- <p>Card</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education5.jpg" data-gall="portfolioGallery" class="venobox" title="Education"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala1.jpeg" class="img-fluid" alt="Kala Vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kala Vinyasa</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala1.jpeg" data-gall="portfolioGallery" class="venobox" title="kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/sports4.jpg" class="img-fluid" alt="Sports">
            <div class="portfolio-info" title="Sports">
              <h4>Sports</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/sports4.jpg" data-gall="portfolioGallery" class="venobox" title="sports"><i class="bx bx-plus"></i></a>
                <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education3.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <!-- <p>Card</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education3.jpg" data-gall="portfolioGallery" class="venobox" title="Education"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education4.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <!-- <p>Card</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education4.jpg" data-gall="portfolioGallery" class="venobox" title="Education"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/kala2.jpeg" class="img-fluid" alt="kala vinyasa">
            <div class="portfolio-info" title="Kala Vinyasa">
              <h4>Kala Vinyasa</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/kala2.jpeg" data-gall="portfolioGallery" class="venobox" title="kalavinyasa"><i class="bx bx-plus"></i></a>
                <a href="kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-acc">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/food.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Accommodation</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/food.jpg" data-gall="portfolioGallery" class="venobox" title="Accommodation"><i class="bx bx-plus"></i></a>
                <a href="kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-acc">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/about2.jpg" class="img-fluid" alt="Accommodation">
            <div class="portfolio-info" title="Accommodation">
              <h4>Accommodation</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/about2.jpg" data-gall="portfolioGallery" class="venobox" title="Accommodation"><i class="bx bx-plus"></i></a>
                <a href="kalavinyasa.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="this.cmsdata.gallery.length > 0" class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

      <div *ngFor="let g of this.cmsdata.gallery" class="col-lg-4 col-md-6 portfolio-item {{g.subheading}}">
        <div class="portfolio-wrap">
          <img src="{{g.image}}" class="img-fluid" alt="{{g.heading}}">
          <div class="portfolio-info" title="{{g.heading}}">
            <h4>Sports</h4>
            <!-- <p>App</p> -->
            <div class="portfolio-links">
              <a href="{{g.image}}" data-gall="portfolioGallery" class="venobox" title="{{g.heading}}"><i class="bx bx-plus"></i></a>
              <a href="Sports.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </section>
<!-- End Portfolio Section -->
</main><!-- End #main -->
