import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VinyasaService {
  dblist: AngularFireList<any>;
  datas: CMS[] = [];

  news: CMS[] = [];
  banner: CMS[] = [];
  donates: CMS[] = [];
  latestnews: CMS[] = [];
  gallery: CMS[] = [];
  activities: CMS[] = [];
  corporate: CMS[] = [];
  students: CMS[] = [];
  studentfeedback: CMS[] = [];

  constructor(private firbase: AngularFireDatabase) {
    this.fillcmsdata();
   }

  getData() {

    this.dblist = this.firbase.list('SathyaList');
    return this.dblist.snapshotChanges();
  }



  async fillcmsdata() {
    await this.getData().subscribe(
        (list) => {
          this.datas = list.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val()
            };
          });

          this.banner = this.datas.filter(x => x.template === 'Banner' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.news = this.datas.filter(x => x.template === 'News' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.donates = this.datas.filter(x => x.template === 'Donates' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.latestnews = this.datas.filter(x => x.template === 'LatestNews' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.gallery = this.datas.filter(x => x.template === 'Gallery' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.activities = this.datas.filter(x => x.template === 'Activities' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);
          this.corporate = this.datas.filter(x => x.template === 'Corpose' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);
          this.students = this.datas.filter(x => x.template === 'Students' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);
          this.studentfeedback = this.datas.filter(x => x.template === 'StudentFeedback' && x.active === true).sort((a, b) => (a.order > b.order) ? 1 : -1);


        }
      );
      return '';
    }
  }


export interface CMS {
  $key;
  template;
  heading;
  subheading;
  content;
  image: any;
  active;
  order:number;
}
