<main id="main">

  <!-- ======= Breadcrumbs ======= -->

  <section class="hero-wrap hero-wrap-2 backnews">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">News / Events</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>News / Events<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->

  <section id="services" class="services">
    <div class="container">

      <div class="section-title" data-aos="fade-up">
        <h2><strong> Media </strong></h2>
      </div>

      <!-- <div class="row">

          <div *ngFor="let g of this.cmsdata.news" class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up">

              <div class="icon"><img src="{{g.image}}" alt="News" class="rounded" height="75" width="75"></div>
              <h4 class="title"><a href="{{g.content}}" target="blank">{{g.heading}}</a></h4>
              <p class="description">{{g.subheading}}</p>
            </div>
          </div>



      </div> -->

      <div class="videos">
        <div class="videos__container">
          <!-- Single Video starts -->
          <div *ngFor="let g of this.cmsdata.news" class="video">
            <div class="video__thumbnail">
              <img src="{{g.image}}" alt="" />
            </div>
            <div class="video__details">
              <div class="title">
                <h3><a href="{{g.content}}" target="_blank">
                  {{g.heading}}</a></h3>
              </div>
            </div>
          </div>
          <!-- Single Video Ends -->

        </div>
      </div>
    </div>
  </section><!-- End Services Section -->


  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Articles</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-app" (click)="Load()">Newspaper</li>
            <!-- <li data-filter=".filter-card">Education</li>
            <li data-filter=".filter-web">Kala Vinyasa</li> -->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/newimage.jpg" class="img-fluid" alt="News">
            <div class="portfolio-info" title="News">
              <h4>Newspaper</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/newimage.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="News.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/r1.jpg" class="img-fluid" alt="News">
            <div class="portfolio-info" title="News">
              <h4>Newspaper</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/r1.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="News.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/r2.jpg" class="img-fluid" alt="News">
            <div class="portfolio-info" title="News">
              <h4>Newspaper</h4>
              <!-- <p>Web</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/r2.jpg" data-gall="portfolioGallery" class="venobox" title="Kala Vinyasa"><i class="bx bx-plus"></i></a>
                <a href="News.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/r3.jpg" class="img-fluid" alt="News">
            <div class="portfolio-info" title="News">
              <h4>Newspaper</h4>
              <!-- <p>App</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/r3.jpg" data-gall="portfolioGallery" class="venobox" title="Sports"><i class="bx bx-plus"></i></a>
                <a href="News.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap" title="news">
            <img src="assets/img/portfolio/r4.jpeg" class="img-fluid" alt="News">
            <div class="portfolio-info" title="News">
              <h4>Newspaper</h4>
              <!-- <p>Card</p> -->
              <div class="portfolio-links">
                <a href="assets/img/portfolio/r4.jpeg" data-gall="portfolioGallery" class="venobox" title="Education"><i class="bx bx-plus"></i></a>
                <a href="News.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Portfolio Section -->

</main>
