<main id="main">

  <!-- ======= Breadcrumbs ======= -->

  <section class="hero-wrap hero-wrap-2 backnews">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">Latest Activities</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>Latest Activities<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

 <br>
<br>
  <section id="blog" class="blog">
    <div class="container" >

      <div class="section-title">
        <h2 data-aos="fade-up">Latest Activities of Vinyasa Trust</h2>
      </div>
      <div class="row" >

        <div *ngFor="let g of this.cmsdata.activities" class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="{{g.image}}" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              {{g.heading}}
            </h2>

            <div class="entry-content">
              <p>
                {{g.content}}
                 </p>
            </div>
          </article>
        </div>


      </div>
    </div>
  </section>

</main>
