 <!-- ======= Breadcrumbs ======= -->

 <section class="hero-wrap hero-wrap-2 back1">
  <div class="overlay">

  </div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
        </p>
        <h1 class="mb-2 bread">Donor List</h1>

        <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                class="ion-ios-arrow-forward"></i></a></span> <span>Donor List <i
              class="ion-ios-arrow-forward"></i></span></p>
      </div>
    </div>
  </div>
</section>

<!-- ======= Featured Section ======= -->




<section class="section-team">
  <div class="container">
       <!-- Start Header Section -->
       <div class="row justify-content-center text-center">
            <div class="col-md-8 col-lg-6">
                 <div class="header-section">
                      <!-- <h3 class="small-title">Our Experts</h3>   -->
                      <h2 class="title">Current month Donar list</h2>
                 </div>
            </div>
       </div>
        <!-- / End Header Section -->
        <div class="row">
          <!-- Start Single Person -->
          <div *ngFor="let g of this.cmsdata.donates" class="col-sm-6 col-lg-4 col-xl-3">
               <div class="single-person">
                    <div class="person-image">
                         <img src="{{g.image}}" alt="">
                         <!-- <span class="icon">
                              <i class="fab fa-html5"></i>
                         </span>   -->
                    </div>
                    <div class="person-info">
                         <h3 class="full-name">{{g.heading}}</h3>
                         <span class="full-name">{{g.subheading}}</span>
                    </div>
               </div>
          </div>
        </div>
  </div>
</section>

