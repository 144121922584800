// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCGeonraSyanp-PvFKmX48y9QDGw1N5J9A",
    authDomain: "vinyasatrust2021.firebaseapp.com",
    databaseURL: "https://vinyasatrust2021-default-rtdb.firebaseio.com",
    projectId: "vinyasatrust2021",
    storageBucket: "vinyasatrust2021.appspot.com",
    messagingSenderId: "531052222087",
    appId: "1:531052222087:web:3d5efc98bdf34b80ef0a22",
    measurementId: "G-LNSHYNJEZV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
