<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="hero-wrap hero-wrap-2 backedu">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">VidyaVinyasa</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>VidyaVinyasa<i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Breadcrumbs -->


  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2 style="font-weight: 700;">Education</h2>
        <p style="text-align: center;">Empowering Through Knowledge</p> <br>
        <p style="text-align: justify;">VINYASA TRUST supports 50+ differently challenged and underprivileged people to pursue higher education in the institutes of their choice through: </p> <br>
        <p style="text-align: left;"> <strong>  1. </strong>A digital audio library</p>
        <p style="text-align: left;"><strong>  2. </strong> Scheduled teaching sessions with our extremely dedicated volunteer teams</p> <br>
        <p style="text-align: justify;">Their educational expenses including the study material in accessible formats, are fully taken care of by our institute.</p> <br>
        <p style="text-align: justify;">Be it the bachelor's or master's degree, or even KAS, our students can achieve their goals with the help of our volunteer teams.</p>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="150">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active" (click)="Load()">All</li>
            <li data-filter=".filter-app" (click)="Load()">Education</li>
            <!-- <li data-filter=".filter-card">Card</li> -->
            <li data-filter=".filter-web" (click)="Load()">Training</li>
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education.jpg" data-gall="portfolioGallery" class="venobox" title="Education"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education3.jpg" class="img-fluid" alt="Training">
            <div class="portfolio-info" title="Training">
              <h4>Training</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education3.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education2.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education2.jpg" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-plus"></i></a>
                <a href="Education.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education1.jpg" class="img-fluid" alt="Education">
            <div class="portfolio-info" title="Education">
              <h4>Education</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education1.jpg" data-gall="portfolioGallery" class="venobox" title="Card 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education5.jpg" class="img-fluid" alt="Training">
            <div class="portfolio-info" title="Training">
              <h4>Training</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education4.jpg" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/education4.jpg" class="img-fluid" alt="Training">
            <div class="portfolio-info" title="Training">
              <h4>Training</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/education4.jpg" data-gall="portfolioGallery" class="venobox" title="App 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Portfolio Section -->
</main><!-- End #main -->
