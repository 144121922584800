import { Component, OnInit } from '@angular/core';
declare var $:any;
declare var AOS:any;



@Component({
  selector: 'app-kalavinyasa',
  templateUrl: './kalavinyasa.component.html',
  styleUrls: ['./kalavinyasa.component.css']
})
export class KalavinyasaComponent implements OnInit {

  constructor() {

   }

  ngOnInit(): void {

    window.scroll(0,0);

    setTimeout(() => {
      this.Load();
      }, 1000);

    }

    Load(){
      var portfolioIsotope = $('.portfolio-container').isotope({
        itemSelector: '.portfolio-item'
      });

      $('#portfolio-flters li').on('click', function() {
        $("#portfolio-flters li").removeClass('filter-active');
        $(this).addClass('filter-active');

        portfolioIsotope.isotope({
          filter: $(this).data('filter')
        });

      });
      // Initiate venobox (lightbox feature used in portofilo)
      $(document).ready(function() {
        $('.venobox').venobox({
          'share': false
        });
      });

 // Initi AOS
 AOS.init({
  duration: 800,
  easing: "ease-in-out"
});

    // Portfolio details carousel
    $(".portfolio-details-carousel").owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      items: 1
    });
    }


}
