<main id="main">

  <!-- ======= Breadcrumbs ======= -->

  <section class="hero-wrap hero-wrap-2 backabt">
    <div class="overlay">

    </div>
    <div class="container">
      <div class="row no-gutters slider-text align-items-center justify-content-center">
        <div class="col-md-9 ftco-animate text-center">
          <p style="text-align: center; color: rgb(245, 237, 237); font-weight: 600;">All donations comes tax exemption under section 80G of Indian Income Tax Act
          </p>
          <h1 class="mb-2 bread">About Us</h1>

          <p class="breadcrumbs"><span class="mr-2"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home <i
                  class="ion-ios-arrow-forward"></i></a></span> <span>About us <i
                class="ion-ios-arrow-forward"></i></span></p>
        </div>
      </div>
    </div>
  </section>






      <!-- ======= About Section ======= -->
      <section id="about" class="about section-bg">
        <div class="container">

          <div class="row">
            <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
              <a href="https://www.youtube.com/watch?v=pNkuDdbPPk0" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
            </div>

            <div class="col-xl-7 col-lg-6 icon-boxes flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <!-- <h4 data-aos="fade-up">About us</h4> -->
              <h3 data-aos="fade-up" style="text-align: center;">Get to know the Vinyasa Family</h3>
              <p data-aos="fade-up" style="text-align: justify;">With the sole objective of making a difference through education, sports, etc., VINYASA Trust for the differently challenged, an NGO, was established on 26th Feb 2018 by two differently challenged persons, Mr. Abdul Javeed and Mr. Madhusudhan K, along with the other trustees, Dr. Vishnu Bharath Alampalli (CA), Smt. Vijaya Sundararaman and Smt. R. Nagarathna.
              </p>

              <div class="icon-box" data-aos="fade-up">
                <div class="icon"><img src="assets/img/team/Mission3.png" alt="Our Mission" class="rounded" height="60" width="60"></div>  <!--<i class="bx bx-fingerprint">-->
                <h4 class="title"><a href="">Our Mission</a></h4>
                <p class="description" style="text-align: justify;">To support the visually impaired, physically challenged, speech and hearing impaired, mentally challenged and underprivileged people through developmental initiatives of education, technology, social and sports/cultural aspects.</p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><img src="assets/img/team/vision.png" alt="Our Vision" class="rounded" height="60" width="60"></div> <!--<i class="bx bx-gift"></i>-->
                <h4 class="title"><a href="">Our Vision</a></h4>
                <p class="description" style="text-align: justify;">CHANGES FOR THE BETTERMENT
                  Enable the differently challenged underprivileged students from urban and rural Karnataka to establish themselves in the society by pursuing a bright, successfulcareer and thereby, lead a happy life.</p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><img src="assets/img/team/value1.png" alt="Our Values" class="rounded" height="60" width="60"></div>  <!--<i class="bx bx-atom"></i>-->
                <h4 class="title"><a href="">Our Values</a></h4>
                <p class="description" style="text-align: justify;">Make the students aware of their responsibilities-- Socio, Economic, Health / Food, Art / Culture, Sports, and most importantly, towards the Nation.</p>
              </div>

            </div>
          </div>

        </div>
      </section><!-- End About Section -->

  <!-- ======= Our Team Section ======= -->
  <!-- <section id="team" class="team section-bg">
    <div class="container">

      <div class="section-title">
        <h2 style="font-weight: 700;">Our Team</h2>

      </div>

      <div class="row">

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/Abduljaveed.png" class="img-fluid" alt="Abdul Javeed">
              <div class="social">

                <a href="https://www.facebook.com/pages/category/Nonprofit-Organization/Vinyasa-Trust-for-Differently-Challenged-398059794056186/" target="blank"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/trustvinyasa/?hl=en" target="blank"><i class="icofont-instagram"></i></a>

              </div>
            </div>
            <div class="member-info">
              <h4>Mr. Abdul Javeed</h4>
              <span>Founder & Managing Trustee</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/Madhu1.png" class="img-fluid" alt="Madhusudhan K">
              <div class="social">

                <a href="https://www.facebook.com/pages/category/Nonprofit-Organization/Vinyasa-Trust-for-Differently-Challenged-398059794056186/" target="blank"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/trustvinyasa/?hl=en" target="blank"><i class="icofont-instagram"></i></a>

              </div>
            </div>
            <div class="member-info">
              <h4>Mr. Madhusudhan K</h4>
              <span>Trustee</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/VijayaMam.png" class="img-fluid" alt="Vijaya Sundararaman">
              <div class="social">

                <a href="https://www.facebook.com/pages/category/Nonprofit-Organization/Vinyasa-Trust-for-Differently-Challenged-398059794056186/" target="blank"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/trustvinyasa/?hl=en" target="blank"><i class="icofont-instagram"></i></a>

              </div>
            </div>
            <div class="member-info">
              <h4>Mrs. Vijaya Sundararaman
              </h4>
              <span>Trustee</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/Nagarathana.png" class="img-fluid" alt="Nagarathana">
              <div class="social">

                <a href="https://www.facebook.com/pages/category/Nonprofit-Organization/Vinyasa-Trust-for-Differently-Challenged-398059794056186/" target="blank"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/trustvinyasa/?hl=en" target="blank"><i class="icofont-instagram"></i></a>

              </div>
            </div>
            <div class="member-info">
              <h4>Mrs. Nagarathana</h4>
              <span>Trustee</span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <div class="member-img">
              <img src="assets/img/team/Vishnusir.png" class="img-fluid" alt="Vishnu Bharath Alampalli">
              <div class="social">

                <a href="https://www.facebook.com/pages/category/Nonprofit-Organization/Vinyasa-Trust-for-Differently-Challenged-398059794056186/" target="blank"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/trustvinyasa/?hl=en" target="blank"><i class="icofont-instagram"></i></a>

              </div>
            </div>
            <div class="member-info">
              <h4>Dr. Vishnu Bharath Alampalli (CA)
              </h4>
              <span>Trustee</span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section> -->


  <section class="page-section bg-light" id="team">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading text-uppercase">Our Team</h2>
            <!-- <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3> -->
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src="assets/img/team/Abduljaveed.png" alt="..." />
                    <h4>Mr. Abdul Javeed</h4>
                    <p class="text-muted">Founder & Managing Trustee</p>
                    <a class="btn btn-social mx-2" href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank"><i class="icofont-instagram"></i></a>
                    <a class="btn btn-social mx-2" href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank"><i class="icofont-facebook"></i></a>
                    <!-- <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a> -->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src="assets/img/team/Madhu1.jpeg" alt="..." />
                    <h4>Mr. Madhusudhan K</h4>
                    <p class="text-muted">Co-Founder & Trustee</p>
                    <a class="btn btn-social mx-2" href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank"><i class="icofont-instagram"></i></a>
                    <a class="btn btn-social mx-2" href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank"><i class="icofont-facebook"></i></a>
                    <!-- <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a> -->
                </div>
            </div>
            <div class="col-lg-4">
              <div class="team-member">
                  <img class="mx-auto rounded-circle" src="assets/img/team/Vishnusir.png" alt="..." />
                  <a class="text-muted" target="_blank" href="https://www.vishnubharathalampalli.com/home"><h4>Dr. Vishnu Bharath Alampalli (CA)</h4></a>
                  <p class="text-muted">Chairman</p>
                  <a class="btn btn-social mx-2" href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank"><i class="icofont-instagram"></i></a>
                  <a class="btn btn-social mx-2" href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank"><i class="icofont-facebook"></i></a>
                  </div>
          </div>
            <div class="col-lg-4">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src="assets/img/team/VijayaMam.jpeg" alt="..." />
                    <h4>Mrs. Vijaya Sundararaman</h4>
                    <p class="text-muted">Trustee</p>
                    <a class="btn btn-social mx-2" href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank"><i class="icofont-instagram"></i></a>
                    <a class="btn btn-social mx-2" href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank"><i class="icofont-facebook"></i></a>
                    <!-- <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a> -->
                </div>
            </div>
            <div class="col-lg-4">
              <div class="team-member">
                  <img class="mx-auto rounded-circle" src="assets/img/team/Nagarathana.png" alt="..." />
                  <h4>Mrs. Nagarathana</h4>
                  <p class="text-muted">Trustee</p>
                  <a class="btn btn-social mx-2" href="https://instagram.com/stories/vinyasa_trust/2579855867079894921?utm_source=ig_story_item_share&utm_medium=share_sheet" target="_blank"><i class="icofont-instagram"></i></a>
                  <a class="btn btn-social mx-2" href="https://www.facebook.com/profile.php?id=100015178126409" target="_blank"><i class="icofont-facebook"></i></a>
                  <!-- <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a> -->
              </div>
          </div>

        </div>

    </div>
</section>

</main><!-- End #main -->
